import React, { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import Form from 'react-bootstrap/Form';
import { toast } from "react-toastify";
//import LoadingAction from "../../../../components/Loader";
import {jobMarkedAsCompleteChange} from "services/apiServices"
 
const buttonWidth = {
    width: "100%",
};

const EditJob = (props) => {
    const { showSuccess, handleClose,jobId,onDataPass } = props;
    const [selectedValue, setSelectedValue] = useState('');
    

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

    const handleSubmit = (e) => {
        e.preventDefault();

        const payload = {
            "jobId": jobId,
            "marked_as_completed":selectedValue
      
          }
          jobMarkedAsCompleteChange(payload).then(response => {
            onDataPass(response.status)
            if (response.status == 200) {
                handleClose()
              toast.success(response.data.message)
            }
          }).catch(err => {
            toast.error(err.response.data.message)
          })
    };

    return (
        <Modal show={showSuccess} onHide={handleClose} centered size="md">
            <Modal.Header closeButton={true} className="border-0 mt-4 pt-0 pb-2">
                <div className="d-flex justify-content-center w-100">
                    <Modal.Title className="h5">Edit Job</Modal.Title>
                </div>
            </Modal.Header>
            {/* {showLoader && <LoadingAction />} */}
            <Modal.Body className="p-4">
                <form onSubmit={handleSubmit} className="form-login n">
                    <div className="mb-4">
                        <label className="form-label">Mark As Complete</label>
                        <Form.Select 
                        aria-label="Default select example" 
                        className="py-2"  
                        value={selectedValue} 
                         onChange={handleChange}>
                             <option>select</option>
                            <option value="1">Successful</option>
                            <option value="0">Unsuccessful</option>
                        </Form.Select>
                    </div>

                    <div className="text-center ">
                        <button
                            type="submit"
                            className="btn btn-primary py-2 fw-bolder update-class"
                            style={buttonWidth}
                        >
                            Update
                        </button>
                    </div>
                </form>
            </Modal.Body>
        </Modal>
    );
};



export default EditJob